<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          <div class="row">
            <div class="col-md-6 d-flex justify-content-end">

            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >
                        <td slot="created_at" slot-scope="{ item }">
                          {{ momentFilter(item.created_at) }}
                        </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </CRow>
</template>

<script>
const moment = require('moment');
const qs = require('qs')
const axios = require('axios')
export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum: 0,
      warning: false,
      success: false,
      alert_message: '',
      changeModal: false,
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      traffic: {},
      change_traffic: {},
      file_path: '',
      fileData: '',
      tableItems: [],
      tableFields: [
        {key: "client_id", label: "Код клиента"},
        {key: "client_name", label: "ФИО"},
        {key: "value", label: "Сумма"},
        {key: "created_at", label: "Дата"},
      ],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/history-wallets/all').then(response => {
        let history_wallet = response.data.history_wallets;
        for (const [key, value] of Object.entries(history_wallet)) {
          if (value.client != null) {
            value.client_name = value.client.name;
          } else {
            value.client_name = '';
          }
          value.created_at = moment(value.created_at).format('Y/MM/DD')
          app.tableItems.push(value);
          app.all_sum += value.summa;
        }
      }).catch(error => {
        console.log(error)
        console.log(error.response);
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    momentFilter(created_at) {
      return moment(created_at).format('DD.MM.Y')
    }
  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
